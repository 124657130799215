.title-group {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.title-group h1 {
    margin-bottom: 0;
}
.title-group i {
    /* position: absolute;
    top: 0;
    right: 0; */
    font-size: 26px;
    margin: auto 0;
}