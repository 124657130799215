.input-group {
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: 3fr 2fr 0fr;
    column-gap: 10px;
}
.input-group input,
.input-group button {
    margin-bottom: 0;
}
.input-group button {
    color: #ccc;
    border-color: #ccc;
    padding: 15px;
}