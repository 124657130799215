.inventory-tables h2 {
	margin-top: 15px;
}
.table-container {
	overflow-x: auto;
}
table a {
	color: var(--light-purple);
	border-bottom: 1px solid;
	text-decoration: none;
}

table {
	width: 100%;
	border-collapse: collapse;
	overflow-x: auto;
	background: white;
}
th, td {
	padding: 8px;
	border: 1px solid #dddddd;
	white-space: nowrap;
}
th {
	background-color: #f2f2f2;
}
tr td:last-of-type {
	text-align: center;
}
tr td:last-of-type a {
	padding: 0px 15px;
	border-bottom: none;
}