nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
nav p {
    padding: 12px;
}
nav.bottom p {
    padding: 12px 20px;
}
nav.bottom i {
    font-size: 22px;
}
nav a.active {
    background: var(--dark-purple);
    border-radius: 5px;
    color: white;
}
nav.top {
    background: white;
    color: black;
}
nav a {
    margin: 5px 0;
}
nav.bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    left: 0;
    color: #444444;
    padding-bottom: 15px;
    padding-inline: 15px;
}