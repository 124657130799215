.card {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
    text-transform: uppercase;
}
.card.primary {
    background: white;
    color: black;
    text-transform: none;
}
.card div:first-of-type p {
    font-weight: 600;
}
.card.primary div:first-of-type p {
    font-size: 20px;
}
.card p {
    margin: 0;
}
.card i {
    margin: auto 0;
    font-size: 20px;
    color: #ccc;
}
.card div:first-of-type {
    display: flex;
    justify-content: space-between;
}