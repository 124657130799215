.minibuttons-container.right {
	justify-content: right;
	display: flex;
}
.minibuttons-container .btn {
	display: inline-block;
	width: unset;
	border: none;
	background: #f2f2f2;
	font-size: 15px;
	margin-left: 15px;
	padding-inline: 20px;
}
.minibuttons-container .btn.secondary {
	color: white;
	background: var(--red);
}
.minibuttons-container .btn p i {
	margin-left: 5px;
}