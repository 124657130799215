.fancy-button-area.sticky {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
}
.fancy-button-area.sticky .fancy-button-container {
	display: grid;
	grid-template-columns: 20% 80%;
	padding: 10px;
}
.fancy-button-area.sticky .fancy-button-container .btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90%;
	background: #e5e5e5;
	border: none;
	color: #6c6c6c;
}
.fancy-button-area.sticky .fancy-button-container .btn p {
	margin: auto;
}
.fancy-button-area.sticky .fancy-button p {
	font-size: 20px;
}
.fancy-button {
	display: block;
	background: #2c952c;
	text-align: center;
	color: white;
	position: relative;
	background: linear-gradient(-90deg, var(--light-purple), var(--dark-purple));
	background-size: 300% 400%;
	animation: gradient 5s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
	cursor: pointer;
	border-radius: 5px;
	margin-bottom: 15px;
}
.fancy-button > p {
	font-family: "Haas Medium";
	padding: 18px;
	margin-block-start: 0;
	margin-block-end: 0;
}
.fancy-button .right-side {
	position: absolute;
	right: 0;
	top: 0;
	width: 75px;
	background: var(--light-purple);
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	clip-path: polygon(100% -160%, 100% 100%, 0 100%);
	padding-left: 15px;
	border-radius: 0 5px 5px 0;
}
.fancy-button .right-side p {
	position: relative;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}