.bigIcon {
    font-size: 56px;
    border-radius: 100%;
    border: 3px dashed var(--dark-purple);
    width: 90px;
    height: 90px;
    text-align: center;
    margin: 0 auto;
    color: var(--dark-purple);
    display: flex;
    align-items: center;
    justify-content: center;
}

.account-info-group {
    margin-bottom: 50px;
}
.account-info-group > p:first-of-type {
    margin-top: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.account-info-group h1 {
    margin: 0;
}