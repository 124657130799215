.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Dark semi-transparent background */
	z-index: 1000; /* Ensure the overlay is on top of other content */
}

.popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 15px;
	padding: 20px;
	padding-bottom: 0;
	z-index: 1001; /* Ensure the popup is on top of the overlay */
	width: 90%;
}

.popup-content {
	text-align: center;
}
.popup-content p {
	max-width: 300px;
	margin: auto;
}

.popup button {
	margin: 5px;
	padding: 15px 30px;
	cursor: pointer;
	background-color: var(--red);
	color: white;
	border: none;
	border-radius: 5px;
	display: inline-block;
	width: auto;
}
.popup button:last-of-type {
	margin-left: 80px;
}
.popup button:first-of-type {
	background: #f2f2f2;
	color: #363636;
	border: 1px solid #bcbcbc;
}

.popup .button-container {
	position: relative;
	bottom: -24px;
}
