.loading-placeholders h1,
.loading-placeholders p {
    display: inline-block;
    background: #ccc;
}
.loading-placeholders h1 {
    padding: 20px 100px;
}
.loading-placeholders p {
    padding: 10px 55px;
    display: block;
    width: min-content;
}
.loading-placeholders .card.button p {
    margin-bottom: 0 !important;
    width: 33% !important;
}
.loading-placeholders .card div:first-of-type p,
.loading-placeholders .card div:last-of-type p:first-of-type {
    margin-bottom: 5px;
}
.loading-placeholders .card div:last-of-type p:first-of-type {
    padding: 10px 65px;
    width: 66%;
}
.loading-placeholders .card div:last-of-type p:last-of-type {
    width: 44%;
}
.text-input-placeholder,
.desc-input-placeholder {
    display: block;
    margin-bottom: 15px;
    border-radius: 5px;
}
.text-input-placeholder {
    padding: 23px;
}
.desc-input-placeholder {
    padding: 40px;
}

.loading-placeholders h1,
.loading-placeholders p,
.text-input-placeholder,
.desc-input-placeholder {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    position: relative;
    overflow: hidden;
}
@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}